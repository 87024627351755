export const SPACE = process.env.SPACE;
export const GITHUB_CLIENT_ID = process.env.GITHUB_CLIENT_ID;
export const GITHUB_CLIENT_SECRET = process.env.GITHUB_CLIENT_SECRET;
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET;
export const NEXTAUTH_URL = process.env.NEXTAUTH_URL;
export const MONGODB_URI = process.env.MONGODB_URI;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;
export const NODE_ENV = process.env.NODE_ENV;

export const MOBILE_RESOLUTION = 435;
export const TABLET_RESOLUTION = 1024;

export const PASSWORD_REGEX =
  /^^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()-_=+{};:,.<>?]{8,}$/;
// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;
export const PHONE_REGEX = /\d{3}\s?\d{3}-\d{2}-\d{2}/;
export const NAME_REGEX = /^[a-zA-Zа-яА-ЯёЁ]+$/;
export const MESSAGE_REGEX = /^[a-zA-Zа-яА-ЯёЁ0-9\s.,!?-]+$/;

export const EMPTY_META = {
  title: 'Workice',
  description: undefined,
  image: undefined,
  canonical: undefined,
  h1: undefined,
  keywords: undefined,
  robots: undefined,
  text: undefined,
};

export const EMPTY_IMAGE = '/png/empty.png';

export const CONTACTS = [
  {
    id: 'telegram',
    icon: '/svg/telegram.svg',
    value: 'Telegram',
    link: 'https://t.me/boymep',
  },
  {
    id: 'whatsapp',
    icon: '/svg/whatsapp.svg',
    value: 'WhatsApp',
    link: 'https://wa.me/79039109854',
  },
  {
    id: 'instagram',
    icon: '/svg/instagram.svg',
    value: 'Instagram',
    link: 'https://www.instagram.com/workice/',
  },
  {
    id: 'email',
    icon: '/svg/mail.svg',
    value: 'mail@workice.ru',
    link: 'mailto:mail@workice.ru',
  },
  // {
  //   id: 'github',
  //   icon: '/svg/github.svg',
  //   value: 'Github',
  //   link: 'https://github.com/WouterLab',
  // },
  // {
  //   id: 'vk',
  //   icon: '/svg/vk.svg',
  //   value: 'VK',
  //   link: 'https://vk.com/workice',
  // },
];

export const BLOCK_IDS = {
  HOME: 'home',
  ABOUT: 'about',
  FEATURES: 'features',
  PRICING: 'pricing',
  FEEDBACK: 'feedback',
};

export const COLORS = {
  PRIMARY: '#40a5e8',
  DANGER: '#ff4d4d',
  WARNING: '#ffa500',
  WHITE: '#ffffff',
  BLACK: '#191919',
  SUCCESS: '#008000',
  GRAY_BLACK: '#515151',
  GRAY: '#eaeaea',
};
