import cn from 'classnames';
import styles from './Backdrop.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

type BackdropProps = {
  className?: string;
  visible: boolean;
  onClick: () => void;
};

export function Backdrop({ className, visible, onClick }: BackdropProps) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={wrapperClassName}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: 'linear' }}
          onClick={onClick}
        />
      )}
    </AnimatePresence>
  );
}
