import { SwitchSize } from './types';

export const SizeMap: Record<
  SwitchSize,
  { height: number; width: number; handleDiameter: number }
> = {
  [SwitchSize.Small]: {
    width: 24,
    height: 14,
    handleDiameter: 12,
  },
  [SwitchSize.Big]: {
    width: 36,
    height: 20,
    handleDiameter: 16,
  },
};
